define("discourse/plugins/waze-chat/discourse/connectors/user-profile-controls/user-profile-waze-chat-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-expect-error no types

  // @ts-expect-error no types

  // @ts-expect-error no types

  class UserCardWazeChatButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "wazeChatSdkPromise", [_service.inject]))();
    #wazeChatSdkPromise = (() => (dt7948.i(this, "wazeChatSdkPromise"), void 0))();
    static shouldRender(outletArgs, helper) {
      return helper.siteSettings.waze_chat_enabled;
    }
    startChat(model, done) {
      const user = this.args.outletArgs.model;
      this.wazeChatSdkPromise.then(sdk => {
        sdk.startChat(user.external_info?.external_id, {
          userName: user.username
        });
      });
      done?.();
    }
    static #_3 = (() => dt7948.n(this.prototype, "startChat", [_object.action]))();
  }
  _exports.default = UserCardWazeChatButton;
});