define("discourse/plugins/waze-chat/discourse/templates/connectors/user-profile-controls/user-profile-waze-chat-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.args.outletArgs.model.can_waze_chat_user}}
    <DButton
      @class="btn-primary"
      @icon="chat"
      @label="js.waze.chat.user_card.start_chat"
      @action={{action "startChat" this.args.outletArgs.model}}
    />
  {{/if}}
  */
  {
    "id": "nVdVnbMf",
    "block": "[[[41,[30,0,[\"args\",\"outletArgs\",\"model\",\"can_waze_chat_user\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@icon\",\"@label\",\"@action\"],[\"btn-primary\",\"chat\",\"js.waze.chat.user_card.start_chat\",[28,[37,2],[[30,0],\"startChat\",[30,0,[\"args\",\"outletArgs\",\"model\"]]],null]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/waze-chat/discourse/templates/connectors/user-profile-controls/user-profile-waze-chat-button.hbs",
    "isStrictMode": false
  });
});